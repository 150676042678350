export const CART_SET_ITEM = 'CART_SET_ITEM';
export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_UPDATE_QUANTITIES = 'CART_UPDATE_QUANTITIES';
export const CART_ADD_ITEM_LOCAL = 'CART_ADD_ITEM_LOCAL';
export const CART_REMOVE_ITEM_LOCAL = 'CART_REMOVE_ITEM_LOCAL';
export const RESET_LOCAL_CART = 'RESET_LOCAL_CART';
export const AAD_LOCAL_CART_TO_DB = 'AAD_LOCAL_CART_TO_DB';
export const CHANGING_SHIPPING_PRICE = 'CHANGING_SHIPPING_PRICE';
export const CART_UPDATE_OPTIONS_LOCAL = 'CART_UPDATE_OPTIONS_LOCAL';
